import React                 from 'react';
import { Flex, Box, Center } from '@chakra-ui/react';
import Img                   from 'gatsby-image';
import { graphql }           from 'gatsby';

import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import HeaderMedia   from '@interness/theme-default/src/components/HeaderMedia';
import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import { findMedia } from '@interness/web-core/src/components/_helpers';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Separator     from '@interness/web-core/src/components/structure/Separator/Separator';
import Link          from '@interness/web-core/src/components/elements/Link/Link';

import Subnavigation from '../components/Subnavigation';

const EuroPalettenPage = (props) => {
  return (
    <>
      <HeaderMedia id="produkte"/>
      <Subnavigation title="Produkte" menu={[
        {
          'displayName': 'Fertigung',
          'path': '/fertigung'
        },
        {
          'displayName': 'Europaletten',
          'path': '/europaletten'
        },
        {
          'displayName': 'Paletten 1/2/3 weg',
          'path': '/paletten-123-weg'
        },
        {
          'displayName': 'Sonderpaletten',
          'path': '/sonderpaletten'
        },
        {
          'displayName': 'Chemiepaletten',
          'path': '/chemiepaletten'
        },
        {
          'displayName': 'Euro-Gitterboxen',
          'path': '/euro-gitterboxen'
        },
        {
          'displayName': 'Vermietung / Reparaturen',
          'path': '/vermietung-reparaturen'
        }
      ]}/>
      <Spacer/>
      <Wrapper>
        <Heading>Europaletten</Heading>

        <Center textAlign='center'>
          Deutsche EURO-Flachpalette 800 x 1200 mm. Tauschpalette nach UIC- Merkblatt 435-2 für den europäischen
          Palettenpool.
        </Center>

        <Spacer/>

        <Flex flexWrap="wrap">
          <Box width={['100%', '100%', '40%', '40%']}>
            <h3>EURO-Flachpalette</h3>

            Kennzeichen und Qualitätsmerkmale

            <ul>
              <li>EPAL Brandzeichen</li>
              <li>Brandzeichen einer europäischen Bahngesellschaft (z.B. DB)</li>
              <li>Signierklammer mit der Gravur RAL RG 993 für gütegeprüfte Flachpalette</li>
              <li>EPAL-Brandzeichen der European Pallet Association, sowie der Hersteller-Code</li>
              <li>EUR-Brandzeichen zeigt das Symbol des Europäischen Paletten-Pools</li>
              <li>Reparaturprüfnagel mit EPAL-Qualitätszeichen für qualitätsgeprüfte Flachpaletten nach der Reparatur
              </li>
              <li>genormtes Nagelbild</li>
              <li>angefaßte Bodenbretter</li>
              <li>keine Baumkanten an den Querbrettern</li>
            </ul>

          </Box>

          <Box width={['100%', '100%', '60%', '60%']} px={6}>
            <Center>
              <Img fixed={findMedia(props.data.collection.images, 'pg-euro-palette').childImageSharp.fixed}/>
            </Center>
          </Box>
        </Flex>

        <Separator/>

        <Flex flexWrap="wrap">
          <Box width={['100%', '100%', '33%', '33%']}>
            <h3>IPPC Logo</h3>
            <Img fixed={findMedia(props.data.collection.images, 'ippc_logo').childImageSharp.fixed}/>
          </Box>
          <Box width={['100%', '100%', '33%', '33%']} px={6}>
            <h3>IPPC Logo mit allen Daten</h3>
            <ul>
              <li>IPPC-Logo</li>
              <li>Länderkennung DE = Deutschland</li>
              <li>Länderkennung NW = Nordrhein - Westfahlen</li>
              <li>Registriernummer</li>
              <li>Heattreatment = Hitzebehandlung</li>
            </ul>
          </Box>
          <Box width={['100%', '100%', '33%', '33%']}>
            <h3>EPAL Brandzeichen</h3>
            <Img fixed={findMedia(props.data.collection.images, 'epal').childImageSharp.fixed}/>
          </Box>
        </Flex>

        <Separator/>

        <ul>
          <li>
            <Link external to={findMedia(props.data.pdf.media, 'linzenz_2015_palettenherstellung').publicURL}>Download
              Lizenz Palettenherstellung (PDF)</Link>
          </li>
          <li>
            <Link external to={findMedia(props.data.pdf.media, 'pefc_zerfikat').publicURL}>Download PEFC Zertifikat
              (PDF)</Link>
          </li>
        </ul>

        <Spacer />

      </Wrapper>
    </>
  )
};

export default EuroPalettenPage;


export const query = graphql`
    query {
        pdf: directusMediaCollection(name: {eq: "pdf"}) {
            media {
                file {
                    localFile {
                        name
                        publicURL
                    }
                }
            }
        }
        collection: directusMediaCollection(name: {eq: "europaletten"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fixed {
                                ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
            }
        }
    }
`;